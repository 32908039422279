
/* Reset */

/* Reset all margins, paddings, and borders */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

/* Remove list styles on ul, ol elements with a class */
ul[class],
ol[class] {
  list-style: none;
}

/* Remove default text styling */
body {
  line-height: 1.5;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove anchor default styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove button default styles */
button,
input,
textarea,
select {
  font: inherit;
  outline: none;
  background: none;
  border: none;
  color: inherit;
}

/* Remove table spacing */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove focus outline for better UX (optional, re-enable for accessibility) */
:focus {
  outline: none;
}

/* Reset media elements */
img,
video {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Remove built-in form element styles */
button,
input {
  overflow: visible;
}

/* Remove form element appearance in Safari */
button,
select {
  appearance: none;
}

/* Set a default cursor for clickable elements */
button,
a {
  cursor: pointer;
}

/* Make sure iframe fills its container */
iframe {
  display: block;
  max-width: 100%;
}

/* Normalize hidden attribute */
[hidden] {
  display: none !important;
}

/* Ensure elements take full width by default */
html,
body {
  width: 100%;
  height: 100%;
}

/* Font */

body {
  margin: 0;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}